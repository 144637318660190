<template>
  <component :is="timeSheetData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="timeSheetData === undefined">
      <h4 class="alert-heading">Erro ao carregar dados</h4>
      <div class="alert-body">
        Lançamento não encontrado
        <b-link class="alert-link" :to="{ name: 'apps-time-sheet-list' }">
          Lista de funcionários
        </b-link>
        for other timeSheets.
      </div>
    </b-alert>

    <template v-if="timeSheetData">
      <b-card>
        <h2 class="mb-2">{{ $t("message.timeSheet.viewTimesheet") }}</h2>

        <b-overlay
          variant="transparent"
          opacity="1.00"
          blur="6px"
          :show="showOverlay"
          rounded="sm"
          no-wrap
        ></b-overlay>

        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group label="Projeto" label-for="projectId">
                <template v-slot:label>
                  {{ $t("message.projects.project") }}
                </template>
                <v-select
                  disabled
                  class="mt-0"
                  v-model="timeSheetData.projectId"
                  :options="projectOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="project-id"
                >
                </v-select>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="TaskId">
                <template v-slot:label>
                  {{ $t("message.activity.task") }}
                </template>
                <v-select
                  disabled
                  class="mb-1"
                  v-model="timeSheetData.taskId"
                  :options="taskOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="task-id"
                >
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group label="Data" label-for="createdDate">
                <template v-slot:label>
                  {{ $t("message.tableHeader.date") }}
                </template>
                <div>
                  <b-form-datepicker
                    id="created-date"
                    v-model="timeSheetData.date"
                    class="form-control"
                    :locale="$i18n.locale"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                    disabled
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <div>
                <label class="form-label">
                  {{ $t("message.timeSheet.hoursReleased") }}</label
                >

                <input
                  disabled
                  v-model="timeSheetData.hoursQuantity"
                  class="form-control"
                  type="number"
                  id="estimatedTime"
                />
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="text-right mt-2 mr-2 mb-2">
                <b-button
                  v-if="restrictions('button_edit_time_sheet')"
                  :to="{
                    name: 'apps-time-sheet-edit',
                    params: { id: timeSheetData.id },
                  }"
                  variant="primary"
                >
                  {{ $t("message.buttons.edit") }}
                </b-button>
                <b-button
                  v-b-modal.modal-delete-confirmation
                  variant="outline-danger"
                  class="ml-1"
                  v-if="restrictions('button_delete_time_sheet')"
                >
                  {{ $t("message.buttons.delete") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>

        <b-modal
          id="modal-delete-confirmation"
          modal-class="modal-danger"
          centered
          :title="$t('delete')"
          hide-footer
        >
          <b-card-text> {{ $t("message.deletAcount") }}</b-card-text>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <b-button
              v-b-modal.modal-danger
              class="mt-2"
              variant="danger"
              :to="{ name: 'apps-time-sheet-list' }"
              @click="deleteTimeSheet()"
            >
              {{ $t("message.buttons.delete") }}
            </b-button>
          </div>
        </b-modal>
      </b-card>
    </template>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormSpinbutton,
  BFormDatepicker,
  BCardText,
  BOverlay,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import getHeader from "@core/utils/auth";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import router from "@/router";
import AccessControl from "@core/utils/access-control";
import store from "@/store";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormSpinbutton,
    flatPickr,
    vSelect,
    BFormDatepicker,
    BCardText,
    BOverlay,
  },

  data() {
    return {
      //Select
      projectOptions: [],
      taskOptions: [],

      showOverlay: false,

      //CurrentUser
      userData: store.state.user.userData,

      //Param
      paramData: store.state.user.paramData,
    };
  },

  async created() {
    this.showOverlay = true;
    var teamsByMember = [];
    await axios
      .get(`${URL_API}team/teamsByMember/${this.userData.id}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.content.length; i++) {
          teamsByMember.push(response.data.content[i].id);
        }
      });

    if (this.restrictions("timesheet-list-all")) {
      var projectList = [];
      var projectId = [];
      await axios
        .get(`${URL_API}project/select_list`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i].name;
            const id = response.data[i].id;
            projectList.push(element);
            projectId.push(id);
            this.projectOptions.push({
              label: projectList[i],
              value: projectId[i],
            });
          }
        });
    } else {
      var projectList = [];
      var projectId = [];
      await axios
        .get(`${URL_API}project/${teamsByMember}/selected_list`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i].name;
            const id = response.data[i].id;
            projectList.push(element);
            projectId.push(id);
            this.projectOptions.push({
              label: projectList[i],
              value: projectId[i],
            });
          }
        });
    }

    var taskList = [];
    var taskId = [];
    await axios
      .get(
        `${URL_API}task/list?page=0&sort=id,${this.paramData.listSort}&size=999`,
        {
          headers: getHeader(this.userData),
        }
      )
      .then((response) => {
        for (let i = 0; i < response.data.content.length; i++) {
          const element = response.data.content[i].subject;
          const id = response.data.content[i].id;
          taskList.push(element);
          taskId.push(id);
          this.taskOptions.push({ label: taskList[i], value: taskId[i] });
        }
      });

      setTimeout(() => {
      this.showOverlay = false;
    }, 1000)
  },

  methods: {
    restrictions(value) {
      return AccessControl(value);
    },

    updateTimeSheet() {
      axios({
        method: "put",
        url: `${URL_API}timeSheet/${this.timeSheetData.id}`,
        headers: getHeader(this.userData),
        data: this.timeSheetData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });
    },

    deleteTimeSheet() {
      axios({
        method: "delete",
        url: `${URL_API}timeSheet/${this.timeSheetData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `O lançamento de horas foi deletado com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            taskData.value = undefined;
          }
        });
    },
  },

  setup() {
    const timeSheetData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}timeSheet/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        timeSheetData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          timeSheetData.value = undefined;
        }
      });

    return {
      timeSheetData,
      vSelect,
    };
  },
};
</script>
